<template>
  <v-list dense>
    <v-list-item link href="https://admin.syx.one" target="_blank">
      <v-list-item-action>
        <v-icon>mdi-wrench</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Administration</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item link href="https://mail.syx.one" target="_blank">
      <v-list-item-action>
        <v-icon>mdi-email</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          eMail
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item link href="https://cloud.syx.one" target="_blank">
      <v-list-item-action>
        <v-icon>mdi-cloud</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          Cloud
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item link href="ts3server://syx.one?port=9987" target="_blank">
      <v-list-item-action>
        <v-icon>mdi-headset</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Teamspeak</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item link href="http://syx.one:3001/" target="_blank">
      <v-list-item-action>
        <v-icon>mdi-earth</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Eco</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
name: "Drawer"
}
</script>

<style scoped>

</style>