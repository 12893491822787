<template>
  <v-app id="inspire">
    <v-content>
      <div class="row mx-3 my-3 justify-center ">
        <v-card
            class="mx-3 my-3"
            elevation="10"
            width="300"
            tile
            v-for="(service, index) in services" :key="index"
        >
          <a
              :href="service.href"
              target="_blank"
              v-if="service.href !== ''"
              style="text-decoration: none;"
          >
            <v-img
                class="white--text align-end"
                height="200px"
                :src="resolveImgUrl(service.img)"
            >
              <v-card-title v-text="service.name"></v-card-title>
            </v-img>
          </a>
          <v-img
              class="white--text align-end"
              height="200px"
              :src="resolveImgUrl(service.img)"
              v-else
          >
            <v-card-title v-text="service.name"></v-card-title>
          </v-img>

          <v-card-actions>
            <v-alert
                class="success mx-auto my-auto"
                width="100%"
                v-if="service.online === true"
            >
              Service up and running.
            </v-alert>
            <v-alert
                class="error mx-auto my-auto"
                width="100%"
                v-else-if="service.online === false"
            >
              Service currently not available.
            </v-alert>
          </v-card-actions>
        </v-card>
      </div>
    </v-content>
  </v-app>
</template>

<script>

import servicesJSON from '../data/services.json'

export default {
  props: {
    source: String,
  },
  data: function () {
    return {
      drawer: null,
      services: servicesJSON,
      statuses: null,
      dialog: false,
    }
  },
  created: function () {
    this.getStatuses();
    this.interval = setInterval(() => this.getStatuses(), 60000);
  },
  methods: {
    resolveImgUrl: function (path) {
      let images = require.context('../assets/', false, /\.png$|\.jpg$/)
      return images("./" + path)
    },
    getStatuses: function () {

      let request = require("request");
      let vm = this;

      let options = {
        method: 'POST',
        url: 'https://api.uptimerobot.com/v2/getMonitors',
        headers:
            {},
        form:
            {
              api_key: 'ur627254-c7a9f11faf3ece9be80f1187',
              format: 'json'
            }
      };
      request(options, function (error, response, body) {
        vm.statuses = body;
        vm.updateStatuses();
      });

    },
    updateStatuses: function () {

      let statuses = JSON.parse(this.statuses);
      let services = this.services;

      for (let i = 0; i < statuses.monitors.length; i++) {
        for (let j = 0; j < services.length; j++) {
          if (services[j].friendly_name === statuses.monitors[i].friendly_name) {
            if (statuses.monitors[i].status === 2) {
              services[j].online = true;
            }
          }
        }
      }

      this.statuses = statuses;
    }
  }
}

</script>