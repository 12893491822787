<template>
  <v-app id="inspire">
    <v-navigation-drawer
        v-model="drawer"
        app
        clipped
        floating
        disable-resize-watcher
        temporary
    >
      <Drawer/>
    </v-navigation-drawer>

    <v-app-bar
        app
        clipped-left
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <v-toolbar-title>SyxOne Status</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <Statuspage/>
    </v-main>

    <v-footer app>
      <a href="https://twitter.com/dillasyx" target="_blank" style="color: white;text-decoration: none;">
        &copy; 2021 DillaSyx
      </a>
      <span class="mx-auto">&nbsp;</span>
      <v-dialog v-model="dialog" width="600px">
        <template v-slot:activator="{ on }">
          <v-btn text x-small v-on="on">Impressum</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Impressum</span>
          </v-card-title>
          <Impressum/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-footer>

    <CookieConsent/>
  </v-app>
</template>

<script>
  import Statuspage from './components/Statuspage';
  import Impressum from "@/components/Impressum";
  import Drawer from "@/components/Drawer";
  import CookieConsent from 'vue-cookieconsent-component';

  export default {
    name: 'App',

    components: {
      Drawer,
      Impressum,
      Statuspage,
      CookieConsent
    },
    props: {
      source: String,
    },
    data: function () {
      return {
        drawer: false,
        dialog: false,
      }
    },
  };
</script>

<style lang="scss">
  $cookieconstent-background: #272727;
  @import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent";
  @import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-bottom";
  @import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-transition";
</style>
